<template>
  <section class="container">
    <stockTopMenu curPosition="考勤管理" windowId="attendance" openDialog="扫码打卡" @openShow="openShow">
    </stockTopMenu>
    <div
      style="
        width: 100%;
        height: 100%;
        width: 100vw;
        height: calc(100vh - 60px);
        padding: 20px;
        display: flex;
        flex-direction: column;
      ">
      <div class="top-box">
        <div class="left-top" v-loading="allLoadingTrue">
          <div class="btn-box">
            <div class="records-box" @click="applicationClass('look')">申请记录</div>
            <div class="title">排班</div>
            <div class="check-box">
              <span
                style="border-radius: 3px 0 0 3px"
                :class="[checkOn == '1' ? 'check-on' : '']"
                @click="checkOnFunction('1')"
                >本周排班</span
              >
              <span
                style="border-radius: 0 3px 3px 0"
                :class="[checkOn == '2' ? 'check-on' : '']"
                @click="checkOnFunction('2')"
                >下周排班</span
              >
            </div>
            <div
              class="btn1"
              @click="editClass"
              v-if="!openEdit && cantEdit && peopleList && peopleList.length"
              v-loading="tableClassLoading">
              编辑排班
            </div>
            <div
              class="btn1"
              @click="editClass2"
              v-if="openEdit && cantEdit && peopleList && peopleList.length"
              v-loading="tableClassLoading">
              确认排班
            </div>
            <div
              class="btn1"
              style="background: #ccc; color: #000"
              @click="notClass"
              v-if="openEdit && cantEdit && peopleList && peopleList.length"
              v-loading="tableClassLoading">
              取消排班
            </div>
            <div class="btn1" v-if="!cantEdit && peopleList && peopleList.length" @click="applicationClass('edit')">
              申请修改排班
            </div>
            <div
              class="btn1"
              @click="
                () => {
                  peopleListFunction()
                  openEdit = false
                }
              ">
              <i class="el-icon-refresh-left" style="margin-right: 5px" title="刷新页面"></i>点击刷新
            </div>
          </div>
          <div class="table-box" v-loading="tableClassLoading">
            <div class="table-top">
              <div
                class="top-item"
                v-for="(item, index) in tableTop"
                :class="[item.label == '周六' || item.label == '周日' ? 'dateNow-box' : '']"
                :key="index">
                <div>{{ item.label }}</div>
                <div v-if="item.value">{{ item.value }}</div>
              </div>
            </div>
            <div
              style="height: calc(100% - 80px); overflow: scroll; overflow-x: overlay; overflow-y: overlay"
              v-if="tableContent.length">
              <div class="table-content" v-for="(item, index) in tableContent" :key="index">
                <div
                  class="content-item"
                  :class="[item.fieldName == 'jobNo' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'jobNo')">
                  {{ item.jobNo }}
                </div>
                <div
                  class="content-item"
                  :class="[item.fieldName == 'userName' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'userName')">
                  {{ item.userName }}
                </div>
                <div
                  class="content-item"
                  :class="[item.fieldName == 'rankName' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'rankName')">
                  {{ item.rankName }}
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Monday.approvalInfoList && item.Monday.approvalInfoList.length) ||
                      Number(item.Monday.shiftField) < 0 ||
                      item.Monday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Monday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Monday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Monday.approvalInfoList && item.Monday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Monday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Monday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Monday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Monday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Monday ? item.Monday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Monday ? item.Monday.label : '' }}</div>
                  <div
                    v-if="item.Monday.approvalInfoList && item.Monday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Monday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Monday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Monday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Monday && item.Monday.approvalInfoList.length
                        ? item.Monday.approvalInfoList[0].applyType + item.Monday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Tuesday.approvalInfoList && item.Tuesday.approvalInfoList.length) ||
                      Number(item.Tuesday.shiftField) < 0 ||
                      item.Tuesday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Tuesday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Tuesday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Tuesday.approvalInfoList && item.Tuesday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Tuesday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Tuesday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Tuesday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Tuesday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Tuesday ? item.Tuesday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Tuesday ? item.Tuesday.label : '' }}</div>
                  <div
                    v-if="item.Tuesday.approvalInfoList && item.Tuesday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Tuesday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Tuesday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Tuesday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Tuesday && item.Tuesday.approvalInfoList.length
                        ? item.Tuesday.approvalInfoList[0].applyType +
                          item.Tuesday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Wednesday.approvalInfoList && item.Wednesday.approvalInfoList.length) ||
                      Number(item.Wednesday.shiftField) < 0 ||
                      item.Wednesday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Wednesday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Wednesday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Wednesday.approvalInfoList && item.Wednesday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Wednesday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Wednesday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Wednesday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Wednesday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Wednesday ? item.Wednesday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Wednesday ? item.Wednesday.label : '' }}</div>
                  <div
                    v-if="item.Wednesday.approvalInfoList && item.Wednesday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Wednesday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Wednesday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Wednesday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Wednesday && item.Wednesday.approvalInfoList.length
                        ? item.Wednesday.approvalInfoList[0].applyType +
                          item.Wednesday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Thursday.approvalInfoList && item.Thursday.approvalInfoList.length) ||
                      Number(item.Thursday.shiftField) < 0 ||
                      item.Thursday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Thursday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Thursday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Thursday.approvalInfoList && item.Thursday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Thursday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Thursday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Thursday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Thursday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Thursday ? item.Thursday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Thursday ? item.Thursday.label : '' }}</div>
                  <div
                    v-if="item.Thursday.approvalInfoList && item.Thursday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Thursday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Thursday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Thursday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Thursday && item.Thursday.approvalInfoList.length
                        ? item.Thursday.approvalInfoList[0].applyType +
                          item.Thursday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Friday.approvalInfoList && item.Friday.approvalInfoList.length) ||
                      Number(item.Friday.shiftField) < 0 ||
                      item.Friday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Friday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Friday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Friday.approvalInfoList && item.Friday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Friday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Friday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Friday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Friday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Friday ? item.Friday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Friday ? item.Friday.label : '' }}</div>
                  <div
                    v-if="item.Friday.approvalInfoList && item.Friday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Friday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Friday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Friday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Friday && item.Friday.approvalInfoList.length
                        ? item.Friday.approvalInfoList[0].applyType + item.Friday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Saturday.approvalInfoList && item.Saturday.approvalInfoList.length) ||
                      Number(item.Saturday.shiftField) < 0 ||
                      item.Saturday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Saturday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Saturday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Saturday.approvalInfoList && item.Saturday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Saturday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Saturday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Saturday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Saturday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Saturday ? item.Saturday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Saturday ? item.Saturday.label : '' }}</div>
                  <div
                    v-if="item.Saturday.approvalInfoList && item.Saturday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Saturday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Saturday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Saturday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Saturday && item.Saturday.approvalInfoList.length
                        ? item.Saturday.approvalInfoList[0].applyType +
                          item.Saturday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
                <div
                  class="content-item"
                  :style="{
                    cursor: 'pointer',
                    backgroundColor:
                      (item.Sunday.approvalInfoList && item.Sunday.approvalInfoList.length) ||
                      Number(item.Sunday.shiftField) < 0 ||
                      item.Sunday.canEditFlag == 'N'
                        ? '#f5f7fa'
                        : ''
                  }"
                  :class="[item.fieldName == 'Sunday' && cantEdit ? 'checkIn-item' : '']"
                  @click="checkRaw(item, index, 'Sunday')">
                  <el-tooltip
                    placement="top"
                    v-if="item.Sunday.approvalInfoList && item.Sunday.approvalInfoList.length">
                    <div slot="content">
                      {{ returnText(item.Sunday.approvalInfoList, 0) }}
                      <br />
                      {{ returnText(item.Sunday.approvalInfoList, 1) }}<br />{{
                        returnText(item.Sunday.approvalInfoList, 2)
                      }}<br />{{ returnText(item.Sunday.approvalInfoList, 3) }}
                    </div>
                    <el-button
                      style="background: none; border: none; font-size: 16px; color: #000; width: 100%; height: 100%"
                      >{{ item.Sunday ? item.Sunday.label : '' }}</el-button
                    >
                  </el-tooltip>
                  <div v-else>{{ item.Sunday ? item.Sunday.label : '' }}</div>
                  <div
                    v-if="item.Sunday.approvalInfoList && item.Sunday.approvalInfoList.length"
                    :style="{
                      position: 'absolute',
                      top: '13px',
                      left: '-30px',
                      fontSize: '13px',
                      color:
                        item.Sunday.approvalInfoList[0].approvalState == '1'
                          ? '#ffc375'
                          : item.Sunday.approvalInfoList[0].approvalState == '2'
                          ? '#43d168'
                          : item.Sunday.approvalInfoList[0].approvalState == '3'
                          ? 'red'
                          : 'red',
                      width: '120px',
                      height: '20px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      transform: 'rotate(-32deg)'
                    }">
                    {{
                      item.Sunday && item.Sunday.approvalInfoList.length
                        ? item.Sunday.approvalInfoList[0].applyType + item.Sunday.approvalInfoList[0].approvalStateDesc
                        : ''
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div style="color: #ccc; text-align: center; font-size: 18px; margin-top: 80px" v-else>暂无数据</div>
          </div>
        </div>
        <!-- <div class="right-top" v-loading="allLoadingTrue">
          <div>
            <div class="time-box" style="margin: 15px 0">{{ currentDate }}</div>
            <div class="time-box" style="margin-bottom: 20px">{{ currentTime }}</div>
          </div>
          <div class="img-box" style="width: 200px; height: 200px" v-loading="touchOn">
            <div ref="qrcodeImage" v-show="qrCodeList && qrCodeList.length" style="width: 100%; height: 100%"></div>
            <div
              v-show="!(qrCodeList && qrCodeList.length)"
              style="
                width: 100%;
                height: 100%;
                color: #ccc;
                background: #f5f7fa;
                text-align: center;
                line-height: 200px;
              ">
              加载失败
            </div>
          </div>
          <div class="tips">扫此码打卡，二维码间隔 {{ qrCodeDuration }} 秒刷新</div>
          <div v-if="!(qrCodeList && qrCodeList.length)" class="flushed" @click="getQrCode">刷新</div>
        </div> -->
      </div>
      <div class="bottom-box">
        <div class="left-bottom" v-loading="allLoadingTrue">
          <div class="btn-box">
            <div class="title">{{ todayDate }} 打卡记录</div>
            <div class="btn1" @click="applicationClass('clock')">考勤报表</div>
            <div class="btn1" @click="refGridManager(gridOptions1)">
              <i class="el-icon-refresh-left" style="margin-right: 5px" title="刷新页面"></i>点击刷新
            </div>
          </div>
          <div style="width: 99.2%">
            <grid-manager :option="gridOptions1"></grid-manager>
          </div>
        </div>
        <div class="right-bottom" v-loading="allLoadingTrue">
          <div class="btn-box">
            <div class="title" style="margin-right: 15px">班次</div>
            <div class="btn1" @click="refGridManager(gridOptions3)">
              <i class="el-icon-refresh-left" style="margin-right: 5px" title="刷新页面"></i>点击刷新
            </div>
          </div>
          <grid-manager :option="gridOptions3"></grid-manager>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="
        applicationType == 'look'
          ? '排班申请记录'
          : applicationType == 'edit' || applicationType == 'item'
          ? '修改排班'
          : ''
      "
      :width="applicationType == 'item' ? '15%' : applicationType == 'clock' ? '98%' : '20%'"
      :top="applicationType == 'edit' ? '30vh' : '16vh'"
      :show-close="applicationType == 'look' || applicationType == 'clock' || applicationType == 'item'"
      :visible.sync="applicationOn">
      <section v-loading="dialogLoading">
        <div v-if="applicationType == 'edit'" style="width: 100%; display: flex; align-items: flex-start">
          <span style="color: red">*</span>修改排班原因：<el-input
            style="width: 200px; margin-left: 10px"
            type="textarea"
            v-model="reason"
            rows="5"
            placeholder="请输入原因"></el-input>
        </div>
        <div
          v-if="applicationType == 'edit'"
          style="width: 100%; display: flex; justify-content: center; margin-top: 35px">
          <div
            style="
              color: #fff;
              background: #1b92e6;
              border-radius: 3px;
              margin-right: 30px;
              padding: 3px 25px;
              cursor: pointer;
            "
            @click="saveReason">
            确认
          </div>
          <div
            style="border: 1px solid #ccc; border-radius: 3px; padding: 3px 25px; cursor: pointer"
            @click="
              applicationOn = false
              reason = ''
            ">
            取消
          </div>
        </div>
        <div
          v-if="applicationType == 'item' && marketShiftInfoList && marketShiftInfoList.length"
          style="
            width: 100%;
            height: 300px;
            padding: 10px;
            margin-top: -30px;
            overflow: scroll;
            overflow-x: overlay;
            overflow-y: overlay;
          ">
          <div
            v-for="(item, index) in marketShiftInfoList"
            :key="index"
            :style="{
              width: '100%',
              height: '46px',
              background: item.color,
              textAlign: 'center',
              lineHeight: '46px',
              borderRadius: '5px',
              marginBottom: '10px',
              color: '#fff',
              cursor: 'pointer'
            }"
            @click="changeFieldKey(item)">
            {{ item.shiftFieldKey }}
          </div>
        </div>
        <div v-if="applicationType == 'look'" class="application-box" style="width: 100%">
          <div class="top-box">
            <div class="top-item">状态</div>
            <div class="top-item">提交时间</div>
          </div>
          <div
            style="width: 100%; height: 450px; overflow: scroll; overflow-x: overlay; overflow-y: overlay"
            v-if="applicationList && applicationList.length">
            <div class="content-box" v-for="(item, index) in applicationList" :key="index">
              <div class="content-item">{{ item.state }}</div>
              <div class="content-item">{{ item.applyTime }}</div>
            </div>
          </div>
          <div
            style="width: 100%; height: 450px; color: #ccc; text-align: center; line-height: 450px; font-size: 16px"
            v-else>
            暂无数据
          </div>
        </div>
        <div v-if="applicationType == 'clock'">
          <div style="margin-bottom: 20px">
            人员名称：<el-input
              size="mini"
              style="width: 200px; margin-right: 40px"
              placeholder="请输入"
              v-model="clockName"></el-input>
            月份：<el-date-picker
              size="mini"
              style="width: 200px; margin-right: 40px"
              v-model="clockMonth"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              placeholder="选择月份">
            </el-date-picker>
            <span
              style="
                display: inline-block;
                padding: 4.2px 20px;
                cursor: pointer;
                color: #fff;
                background: #1684fc;
                border-radius: 3px;
              "
              @click="searchOn"
              >查 询</span
            >
          </div>
          <grid-manager :option="gridOptions2"></grid-manager>
        </div>
      </section>
    </el-dialog>
    <el-dialog
      title="扫码打卡"
      width="25%"
      top="22vh"
      :show-close="true"
      :visible.sync="codeOpen"
      :close-on-click-modal="false"
      @close="closeCode">
      <div class="right-top" v-loading="allLoadingTrue">
        <div>
          <div class="time-box" style="margin-bottom: 15px">{{ currentDate }}</div>
          <div class="time-box" style="margin-bottom: 20px">{{ currentTime }}</div>
        </div>
        <div class="img-box" style="width: 200px; height: 200px; margin-bottom: 20px" v-loading="touchOn">
          <div ref="qrcodeImage" v-show="qrCodeList && qrCodeList.length" style="width: 100%; height: 100%"></div>
          <div
            v-show="!(qrCodeList && qrCodeList.length)"
            style="width: 100%; height: 100%; color: #ccc; background: #f5f7fa; text-align: center; line-height: 200px">
            加载失败
          </div>
        </div>
        <div class="tips">扫此码打卡，二维码间隔 {{ qrCodeDuration }} 秒刷新</div>
        <div v-if="!(qrCodeList && qrCodeList.length)" class="flushed" @click="getQrCode" style="margin-top: 30px">
          刷新
        </div>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import stockTopMenu from '../../components/stockTopMenu.vue'
import newPostUrl from '@/libs/http/modules/oa.js'
import tool from '@/utils/tool.js'
import QRCode from 'qrcodejs2'
import log from '../../libs/common/log'
export default {
  name: 'attendance',
  components: {
    stockTopMenu
  },
  data() {
    return {
      codeOpen: false,
      allLoadingTrue: false,
      touchOn: false,
      dialogLoading: false,
      qrCodeList: [], //打卡二维码
      qrCodeDuration: '5', //二维码刷新时间
      restNumLimit: [], //休息限制
      shiftRuleInfoList: [], //排班限制
      clockName: '',
      clockMonth: '',
      applicationList: [],
      reason: '',
      tableClassLoading: false,
      peopleList: [],
      dateClassList: [],
      marketShiftInfoList: [], //可选择班次
      classList: [
        { color: '#40a9ff' },
        { color: '#ffa39e' },
        { color: '#ffa39e' },
        { color: '#ffc069' },
        { color: '#95de64' },
        { color: '#68945c' },
        { color: '#68945c' },
        { color: '#00d6e1' },
        { color: '#be2baf' },
        { color: '#655b4c' }
      ],
      applicationType: '',
      thisWeek: null,
      nextWeekStart: null,
      applicationOn: false,
      editApplyId: '',
      cantEdit: false,
      openEdit: false,
      currentDate: '',
      todayDate: '',
      currentTime: '',
      timer: null,
      checkType: '',
      checkIndex: '',
      tableTop: [],
      tableContent: [],
      thisTableTop: [],
      nextTableTop: [],
      checkOn: '1',
      gridOptions1: {
        gridManagerName: 'attendance1',
        firstLoading: false,
        isCombSorting: false,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '30px',
        pageSize: 100,
        sizeData: [100, 200, 500, 1000],
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                </div>`,

        columnData: [
          { key: 'employeeId', text: '工号', align: 'center' },
          { key: 'userName', text: '姓名', align: 'center' },
          { key: 'shiftName', text: '班次', align: 'center' },
          { key: 'oaApplyTypeDescs', text: 'OA申请', align: 'center' },
          // { key: 'attendanceDayClockStateDesc', text: '结果' },
          { key: 'firstStartClockTimeHMS', text: '上班卡1', align: 'center' },
          { key: 'firstEndClockTimeHMS', text: '下班卡1', align: 'center' },
          { key: 'secondStartClockTimeHMS', text: '上班卡2', align: 'center' },
          { key: 'secondEndClockTimeHMS', text: '下班卡2', align: 'center' }
        ],
        ajaxData: this.marketQueryList, //数据
        supportAjaxPage: true,
        height: '38vh'
      },
      gridOptions2: {
        gridManagerName: 'attendance2',
        firstLoading: true,
        isCombSorting: false,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '30px',
        pageSize: 100,
        sizeData: [100, 200, 500, 1000],
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                </div>`,
        columnData: [
          { key: 'userName', text: '用户名称', align: 'center' },
          { key: 'postNames', text: '岗位', width: '90px', align: 'center' },
          { key: 'workDays', text: '入职天数', align: 'center' },
          { key: 'probationDays', text: '试工(天)', align: 'center' },
          { key: 'shouldAttendanceDay', text: '应出勤天数', align: 'center' },
          { key: 'clockDays', text: '打卡天数', align: 'center' },
          { key: 'restDays', text: '休息天数', align: 'center' },
          { key: 'noClockCount', text: '缺卡次数', align: 'center' },
          { key: 'reissueClockCount', text: '已补卡次数', align: 'center' },
          { key: 'punishAdjustAttendanceCount', text: '追责补卡数', align: 'center' },
          { key: 'lateCount', text: '迟到（次）', align: 'center' },
          { key: 'moderatelyLateCount', text: '中迟到（次）', align: 'center' },
          { key: 'seriouslyLateCount', text: '严重迟到（次）', align: 'center' },
          { key: 'callInCount', text: '调入(次)', align: 'center' },
          { key: 'callOutCount', text: '调出(次)', align: 'center' },
          { key: 'evectionDays', text: '出差(天)', align: 'center' },
          { key: 'trainDays', text: '培训(天)', align: 'center' },
          { key: 'finalLeaveTime', text: '离职', align: 'center' },
          { key: 'absenteeismDays', text: '旷工(天)', align: 'center' },
          { key: 'balanceDays', text: '调休(天)', align: 'center' },
          { key: 'personalLeaveDays', text: '事假(天)', align: 'center' },
          { key: 'marriageLeaveDays', text: '婚假(天)', align: 'center' },
          { key: 'bereavermentLeaveDays', text: '丧假(天)', align: 'center' },
          { key: 'sickLeaveDays', text: '病假(天)', align: 'center' },
          { key: 'springFestivalLeaveDays', text: '春节假期(天)', align: 'center' }
        ],
        ajaxData: this.fetch, //数据
        supportAjaxPage: true,
        height: '52vh',
        // width: '65.6vw',
        rowClick: this.rowdbClick
        // userId: this.$cache.session.get(this.$macro.cache.KEY_USER_ID)
      },
      gridOptions3: {
        gridManagerName: 'attendance3',
        firstLoading: false,
        isCombSorting: false,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '30px',
        pageSize: 100,
        sizeData: [100, 200, 500, 1000],
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                </div>`,
        columnData: [
          { key: 'shiftFieldKey', text: '班次', align: 'center' },
          { key: 'shiftTime', text: '班次时间', align: 'center' }
        ],
        ajaxData: this.classInfoList, //数据
        supportAjaxPage: true,
        height: '38vh',
        width: '100%'
      },

      crtCodeIndex: 0,
      flushCodeInterval: null
    }
  },
  created() {
    this.timer = setInterval(() => {
      const days = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']

      // 获取当前日期
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth() + 1 // 月份是从 0 开始计数的，需要加 1
      const day = String(now.getDate()).padStart(2, '0') // 获取当前日期并格式化为两位数

      // 计算当前日期是星期几
      const currentDayIndex = now.getDay()
      const currentDayOfWeek = days[currentDayIndex === 0 ? 6 : currentDayIndex - 1]

      // 计算当前日期的格式化字符串
      this.currentDate = `当前日期：${year}-${month.toString().padStart(2, '0')}-${day} ${currentDayOfWeek}`
      this.todayDate = `${year}-${month.toString().padStart(2, '0')}-${day}`
      this.getWeekData()

      //判断是否当前日期做高亮
      // this.tableTop.map((item, index) => {
      //   if (index > 2) {
      //     if (item.value == `${month}月${day > 9 ? day : day.slice(1)}日`) {
      //       item.dateNow = true
      //     } else {
      //       item.dateNow = false
      //     }
      //   }
      //   return item
      // })
    }, 100)
    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    this.clockMonth = `${year}-${month}`
    this.getWeekData() //一开始就要获取时间范围
    this.getTableTop()

    this.allLoadingTrue = true
    let tokenTime = tool.data.get('TOKEN_TIME')
    if (tool.data.get('TOKEN') && !this.hasExceededThirtyMinutes(tokenTime)) {
      this.peopleListFunction()
      // this.getQrCode() //获取打卡二维码以及刷新时间
      //调用表格刷新方法是因为表格数据在页面创建前就会请求数据，所以需要重新刷新
      setTimeout(() => {
        GridManager.refreshGrid(this.gridOptions1.gridManagerName, true)
        GridManager.refreshGrid(this.gridOptions3.gridManagerName, true)
      }, 60) //延迟获取表格数据(页面初始化时调用会提示表格没初始化)
      this.allLoadingTrue = false
    } else {
      //新接口调用需要的新token
      const newRequest = async () => {
        try {
          const result = await newPostUrl.newLogin()
          if (result) {
            this.peopleListFunction()
            // this.getQrCode() //获取打卡二维码以及刷新时间
            //调用表格刷新方法是因为表格数据在页面创建前就会请求数据，所以需要重新刷新
            setTimeout(() => {
              GridManager.refreshGrid(this.gridOptions1.gridManagerName, true)
              GridManager.refreshGrid(this.gridOptions3.gridManagerName, true)
            }, 60) //延迟获取表格数据(页面初始化时调用会提示表格没初始化)
            this.allLoadingTrue = false
          }
        } catch (error) {
          this.allLoadingTrue = false
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }
      newRequest()
    }
  },
  destroyed() {
    clearInterval(this.flushCodeInterval)
  },
  computed: {},
  methods: {
    notClass() {
      this.$confirm('提示', {
        title: '提示',
        message: '确定要取消排班？',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(res => {
          this.openEdit = false
        })
        .catch(err => {})
    },
    openShow() {
      this.codeOpen = true
      this.getQrCode()
    },
    closeCode() {
      this.codeOpen = false
      clearInterval(this.flushCodeInterval)
    },
    returnText(item, index) {
      if (!(item && item[index])) {
        return
      }
      return item[index].applyType + item[index].approvalStateDesc
    },

    // 判断是否超时30分钟
    hasExceededThirtyMinutes(tokenTime) {
      if (!tokenTime) {
        return true
      }
      const THIRTY_MINUTES_MS = 30 * 60 * 1000 // 30分钟的毫秒数
      const currentTimestamp = Date.now() // 当前时间戳
      const timeDifference = currentTimestamp - tokenTime // 计算时间差
      return timeDifference >= THIRTY_MINUTES_MS // 判断是否超过30分钟
    },
    refGridManager(gridManager) {
      GridManager.refreshGrid(gridManager.gridManagerName, true)
    },
    getTableTop() {
      const days = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      const today = new Date()
      const dayOfWeek = today.getDay()
      const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // 计算今天距离最近的周一的偏移量
      const now = new Date()
      const month = now.getMonth() + 1 // 月份是从 0 开始计数的，需要加 1
      const day = String(now.getDate()).padStart(2, '0') // 获取当前日期并格式化为两位数

      // 获取当前周
      const thisWeekDates = []
      for (let i = 0; i < 7; i++) {
        const date = new Date(today)
        date.setDate(today.getDate() - offset + i) // 计算当前周每天的日期
        const label = days[i]
        const value = `${date.getMonth() + 1}月${date.getDate()}日`
        thisWeekDates.push({ label, value })
      }

      // 获取下周
      const nextWeekDates = []
      for (let i = 0; i < 7; i++) {
        const date = new Date(today)
        date.setDate(today.getDate() - offset + 7 + i) // 计算下周每天的日期
        const label = days[i]
        const value = `${date.getMonth() + 1}月${date.getDate()}日`
        nextWeekDates.push({ label, value })
      }

      //两周数组进行tab选择
      let list = [{ label: '工号' }, { label: '姓名' }, { label: '职位' }]
      this.thisTableTop = list.concat(thisWeekDates)
      this.nextTableTop = list.concat(nextWeekDates)
    },
    getQrCode() {
      const that = this
      if (this.flushCodeInterval) {
        clearInterval(this.flushCodeInterval)
      }
      if (this.touchOn) {
        return
      }
      this.touchOn = true

      const newRequest = async () => {
        try {
          const result = await newPostUrl.getAttendanceCode({
            marketId: this.$cache.local.get('marketingUnitId')
          })
          if (result) {
            that.qrCodeList = []
            that.crtCodeIndex = 0
            that.qrCodeList = result.list
            this.qrCodeDuration = result.qrCodeDuration
            let dateNum = this.qrCodeDuration * 1000
            this.showQRCode(that.qrCodeList[that.crtCodeIndex])
            that.flushCodeInterval = setInterval(() => {
              if (that.crtCodeIndex < that.qrCodeList.length - 1) {
                that.crtCodeIndex += 1
                const code = that.qrCodeList[that.crtCodeIndex]
                this.touchOn = true
                this.showQRCode(code)
              } else {
                that.crtCodeIndex = 0
                that.getQrCode()
              }
            }, dateNum)
          }
        } catch (error) {
          this.touchOn = false
          this.qrCodeList = []
          clearInterval(that.flushCodeInterval)
        }
      }
      newRequest()
    },
    // 生成二维码
    showQRCode(imgCode) {
      this.touchOn = false
      //先清除已有二维码
      this.$refs.qrcodeImage.innerHTML = ''
      var code = new QRCode(this.$refs.qrcodeImage, {
        text: imgCode,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff'
      })
      // code._el.title = ''
    },
    searchOn() {
      GridManager.refreshGrid(this.gridOptions2.gridManagerName, true)
    },
    marketQueryList(params) {
      let reqData = {
        page: params ? params.page : '1',
        pageSize: params ? params.pageSize : '100',
        marketId: this.$cache.local.get('marketingUnitId')
      }
      return new Promise((resolve, reject) => {
        const newRequest = async () => {
          try {
            const result = await newPostUrl.marketQueryQueryList(reqData)
            const tableData = {
              data: result && result.records ? result.records : [],
              totals: Number(result && result.records ? result.records.length : 0)
            }
            resolve(tableData)
          } catch (error) {
            reject(error)
            // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
          }
        }
        newRequest()
      })
    },
    applicationGetList() {
      const newRequest = async () => {
        try {
          const result = await newPostUrl.marketSchedulesQuery({})
          if (result && result.applyInfoList && result.applyInfoList.length) {
            this.applicationList = result.applyInfoList
          }
          this.dialogLoading = false
        } catch (error) {
          this.dialogLoading = false
          this.applicationList = []
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }
      newRequest()
    },
    saveReason() {
      this.dialogLoading = true
      if (!this.reason || this.reason == '') {
        this.dialogLoading = false
        return this.$message({ type: 'error', message: '请先填写修改原因！', duration: 2000, showClose: true })
      }
      const newRequest = async () => {
        try {
          const result = await newPostUrl.marketSchedulesEditApply({
            reason: this.reason
          })
          if (result) {
            this.reason = ''
            this.deptClass()
            this.applicationOn = false
            this.$message({ type: 'success', message: '申请提交成功', duration: 2000, showClose: true })
          }
          this.dialogLoading = false
        } catch (error) {
          this.dialogLoading = false
          this.tableClassLoading = false
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }
      this.$confirm('确认申请修改排班？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          newRequest()
        })
        .catch(() => {
          this.dialogLoading = false
        })
    },
    checkOnFunction(type) {
      this.checkOn = type
      this.tableTop = type == '1' ? this.thisTableTop : this.nextTableTop
      this.deptClass()
    },

    //以上代码优化
    getWeekData() {
      const now = new Date()
      // 获取当前时间并格式化
      const formatTime = unit => unit.toString().padStart(2, '0')
      this.currentTime = `当前时间：${formatTime(now.getHours())} : ${formatTime(now.getMinutes())} : ${formatTime(
        now.getSeconds()
      )}`

      // 获取周的起始和结束日期
      const getWeekRange = baseDate => {
        const dayOfWeek = baseDate.getDay()
        const startOfWeek = new Date(baseDate)
        startOfWeek.setDate(baseDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1))

        const endOfWeek = new Date(startOfWeek)
        endOfWeek.setDate(startOfWeek.getDate() + 6)

        return {
          startDate: formatDate(startOfWeek),
          endDate: formatDate(endOfWeek)
        }
      }

      // 格式化日期为 YYYY-MM-DD
      const formatDate = date => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
      }

      // 获取当前的周范围
      this.thisWeek = getWeekRange(now)

      // 获取下周的周范围
      const nextWeekStart = new Date(now)
      nextWeekStart.setDate(now.getDate() + 7)
      this.nextWeek = getWeekRange(nextWeekStart)
    },
    applicationClass(type, item) {
      this.applicationOn = true
      this.applicationType = type
      if (type == 'look') {
        this.dialogLoading = true
        this.applicationGetList()
      }
      //考勤报表关闭后第二次开始，打开不会再次请求，所以在每次打开都需要请求
      if (type == 'clock') {
        setTimeout(() => {
          GridManager.refreshGrid(this.gridOptions2.gridManagerName, true)
        }, 60) //加延时是因为组件生成并调用方法比表格创建时还快，需要延时
      }
    },
    //获取店铺人员
    peopleListFunction() {
      this.tableClassLoading = true
      const newRequest = async () => {
        try {
          const result = await newPostUrl.marketRelationUser(this.checkOn == '1' ? this.thisWeek : this.nextWeek)
          this.peopleList = result && result.marketRelationUserInfoList ? result.marketRelationUserInfoList : []
          if (this.peopleList.length) {
            this.checkOnFunction('1')
          } else {
            this.checkOnFunction('1')
            this.tableClassLoading = false
            return this.$message.error('该店铺暂无人员可排班')
          }
        } catch (error) {
          this.tableClassLoading = false
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }
      newRequest()
    },
    deptClass() {
      this.tableClassLoading = true
      const newRequest = async () => {
        try {
          const result = await newPostUrl.marketSchedulesPosQuery(this.checkOn == '1' ? this.thisWeek : this.nextWeek)
          this.shiftRuleInfoList = result && result.shiftRuleInfoList ? result.shiftRuleInfoList : []
          this.restNumLimit = result && result.restNumLimit ? result.restNumLimit : '0'
          const now = new Date()
          const year = now.getFullYear()
          const parseDate = (dateStr, year) => {
            // 使用正则表达式提取月份和日期
            const match = dateStr.match(/(\d+)月(\d+)日/)
            if (match) {
              const month = String(match[1]).padStart(2, '0') // 确保月份为两位数
              const day = String(match[2]).padStart(2, '0') // 确保日期为两位数
              return `${year}-${month}-${day}`
            }
            return '' // 如果格式不匹配，返回 null
          }

          const chineseToEnglishWeekday = chineseWeekday => {
            const chineseWeekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const englishWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

            const index = chineseWeekdays.indexOf(chineseWeekday)
            if (index !== -1) {
              return englishWeekdays[index]
            } else {
              return 'Invalid weekday'
            }
          }

          //周=>用于提交时数据转换
          this.tableTop.forEach((a, index) => {
            if (index > 2) {
              this.dateClassList.push({
                value: a.value,
                schedulesDate: parseDate(a.value, year)
              })
            }
          })

          const formatDate = dateStr => {
            const date = new Date(dateStr)
            const month = date.getMonth() + 1
            const day = date.getDate()

            return `${month}月${day}日`
          }

          const generateTableContent = (data, isEmpty) => {
            return this.tableContent.map(item => {
              this.tableTop.forEach((a, b) => {
                if (b > 2) {
                  if (isEmpty) {
                    // 处理全空排班
                    item[`${chineseToEnglishWeekday(a.label)}`] = {
                      shiftField: '',
                      label: '',
                      schedulesDate: parseDate(a.value, year),
                      approvalInfoList: [],
                      canEditFlag: ''
                    }
                  } else {
                    // 处理有排班的情况
                    const schedule = data.find(c => parseDate(a.value, year) === c.schedulesDate)
                    if (schedule && schedule.marketSchedulesList && schedule.marketSchedulesList.length) {
                      const entry = schedule.marketSchedulesList.find(e => item.userId === e.userId)
                      if (entry) {
                        item[`${chineseToEnglishWeekday(a.label)}`] = {
                          shiftField: entry.shiftField,
                          label: entry.shiftName,
                          schedulesDate: parseDate(a.value, year),
                          approvalInfoList: entry.approvalInfoList,
                          canEditFlag: entry.canEditFlag
                        }
                      }
                    }
                  }
                }
              })
              let itemClass = []
              let topClass = []
              for (let key in item) {
                if (key !== 'userId' && key !== 'isTemporary') {
                  itemClass.push(formatDate(item[key].schedulesDate))
                }
              }
              this.tableTop.forEach((c, d) => {
                if (d > 2) {
                  topClass.push(c.value)
                }
              })

              //判断返回数据日期排班是否和表头日期长度一致，不一致就是某些日期没排班
              if (topClass.length !== itemClass.length) {
                // 获取两个数组的并集
                const union = [...topClass, ...itemClass]

                // 过滤掉那些在两个数组中都出现的元素
                const differentDays = union.filter(day => {
                  return topClass.indexOf(day) < 0 || itemClass.indexOf(day) < 0
                })

                //返回数据不是每个日期都有排班，找出没排班的日期给赋值
                this.tableTop.forEach((e, f) => {
                  if (f > 2) {
                    differentDays.forEach((g, h) => {
                      if (g == e.value) {
                        item[`${chineseToEnglishWeekday(e.label)}`] = {
                          shiftField: '',
                          label: '',
                          schedulesDate: parseDate(e.value, year),
                          approvalInfoList: [],
                          canEditFlag: ''
                        }
                      }
                    })
                  }
                })
              }
              return item
            })
          }

          // 判断排班列表是否为空并生成表内容
          if (result && result.marketSchedulesList && result.marketSchedulesList.length) {
            this.tableContent = JSON.parse(JSON.stringify(this.peopleList))
            this.tableContent = generateTableContent(result.marketSchedulesList, false)
          } else {
            this.tableContent = JSON.parse(JSON.stringify(this.peopleList))
            this.tableContent = generateTableContent([], true)
          }

          //是否可编辑标识
          this.cantEdit = result && result.allowEditFlag && result.allowEditFlag == 'Y' ? true : false
          //是否已编辑id
          this.editApplyId = result && result.applyEditId ? result.applyEditId : ''
          this.tableClassLoading = false
          this.refGridManager(this.gridOptions1)
        } catch (error) {
          this.tableClassLoading = false
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }
      newRequest()
    },
    editClass() {
      if (!this.cantEdit) {
        return this.$message({ type: 'error', message: '周一至周五不可编辑排班！', duration: 2000, showClose: true })
      }
      this.openEdit = true
    },
    editClass2() {
      let resList = JSON.parse(JSON.stringify(this.dateClassList))
      resList = resList
        .map(item => {
          item['schedulesInfoList'] = []
          this.tableContent.forEach(a => {
            for (let key in a) {
              if (item.schedulesDate == a[key].schedulesDate) {
                item['schedulesInfoList'].push({
                  userId: a.userId,
                  shiftField: a[key].shiftField,
                  canEditFlag: a[key].canEditFlag //这里面加这个是因为下面要判断该排班空格是否可以编辑排班，这是排班标识
                })
              }
            }
          })
          return item
        })
        .slice(-7) //避免数组重复

      // 检查 schedulesInfoList 是否包含空的 shiftField(用于检测排班表是否填满)
      const listHave = resList.some(item =>
        item.schedulesInfoList.some(a => (!a.shiftField || a.shiftField === '') && a.canEditFlag !== 'N')
      )
        ? [false]
        : []

      //排班表格规则检验开关
      let classRules = true

      //创建一个班次总和的初始值
      let workList = {}

      this.marketShiftInfoList.forEach(item => {
        workList[item.shiftField] = 0 //所有班次初始值为0
      })

      //循环所有人一周排班计算班次次数
      this.tableContent.forEach(item => {
        for (let key in item) {
          if (item[key] && item[key].shiftField) {
            workList[item[key].shiftField] = workList[item[key].shiftField] + 1
          }
        }
      })

      //排休校验
      for (let key in workList) {
        if (key == 'REST_SHIFT_CLOCK_TIME_CONFIG' && this.restNumLimit !== '0' && workList[key] > this.restNumLimit) {
          classRules = false
        }
      }

      //班次校验
      this.shiftRuleInfoList.forEach(item => {
        for (let key in workList) {
          if (item.shiftField == key) {
            if (workList[key] > item.limitMaxNum || workList[key] < item.limitMinNum) {
              classRules = false
            }
          }
        }
      })

      if (listHave.includes(false)) {
        return this.$message({
          type: 'error',
          message: '请填完整排班班次再进行提交！',
          duration: 2000,
          showClose: true
        })
      }
      const newRequest = async () => {
        try {
          const result = await newPostUrl.marketSchedulesPosSave(resData)
          if (result) {
            this.$message({ type: 'success', message: '编辑成功', duration: 2000, showClose: true })
          }
          this.openEdit = false
          this.deptClass()
        } catch (error) {
          // this.deptClass()
          this.openEdit = false
          this.tableClassLoading = false
          // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
        }
      }

      //只取可编辑排班的数据
      let reqOfterList = resList
        .map(item => {
          item.schedulesInfoList = item.schedulesInfoList.filter(a => a.canEditFlag === 'Y')
          return item
        })
        .filter(item => item.schedulesInfoList.length > 0)

      let resData = { clerkSchedulesInfoList: reqOfterList, editApplyId: this.editApplyId }

      const _this = this

      this.$confirm('是否确认排班？确认后修改排班需向人事申请', '确认排班', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (!classRules) {
            _this
              .$confirm('排班有误，若不重新排班则可点击“确认”提交此排班', '排班提醒', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
              })
              .then(() => {
                _this.tableClassLoading = true
                newRequest()
              })
              .catch(() => {})
          } else {
            this.tableClassLoading = true
            newRequest()
          }
        })
        .catch(() => {})
    },
    checkRaw(item, index, type) {
      if (
        !(this.cantEdit && this.openEdit) ||
        type == 'jobNo' ||
        type == 'rankName' ||
        type == 'userName' ||
        (item[type].approvalInfoList && item[type].approvalInfoList.length) ||
        Number(item[type].shiftField) < 0 || //负数代表出差或者调岗之类的，不给编辑排班
        item[type].canEditFlag == 'N'
      ) {
        return
      }
      this.tableContent.forEach((element, b) => {
        this.$set(this.tableContent[b], 'fieldName', '')
      })
      this.$set(this.tableContent[index], 'fieldName', type)
      this.checkType = type
      this.checkIndex = index

      this.applicationClass('item', item)

      //点击相同去除高亮效果的
      //   if (this.tableContent[index]['fieldName'] && this.checkType == type) {
      //     this.$set(this.tableContent[index], 'fieldName', '')
      //   } else {
      //     this.tableContent.forEach((element, b) => {
      //       this.$set(this.tableContent[b], 'fieldName', '')
      //     })
      //     this.$set(this.tableContent[index], 'fieldName', type)
      //     this.checkType = type
      //   }
    },
    changeFieldKey(item) {
      this.$set(this.tableContent[this.checkIndex][`${this.checkType}`], 'shiftField', item.shiftField)
      this.$set(this.tableContent[this.checkIndex][`${this.checkType}`], 'label', item.shiftFieldKey)
      this.applicationOn = false
    },
    rowdbClick(row) {
      this.textarea2 = row.remark
      // this.showDetail(row)
    },
    fetch(params) {
      let reqData = {
        hideZero: '1',
        page: params ? params.page : '1',
        pageSize: params ? params.pageSize : '10',
        marketId: this.$cache.local.get('marketingUnitId'),
        forMonth: this.clockMonth
      }

      if (this.clockMonth) {
        reqData['forMonth'] = this.clockMonth
      }
      if (this.clockName) {
        reqData['userName'] = this.clockName
      }
      return new Promise((resolve, reject) => {
        const newRequest = async () => {
          try {
            const result = await newPostUrl.queryMarketAttendanceMonthly(reqData)
            const tableData = {
              data: result && result.records ? result.records : [],
              totals: Number(result && result.records ? result.records.length : 0)
            }
            resolve(tableData)
          } catch (error) {
            reject(error)
            // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
          }
        }
        newRequest()
      })
    },
    classInfoList() {
      return new Promise((resolve, reject) => {
        const newRequest = async () => {
          try {
            const result = await newPostUrl.marketQueryMarketShiftInfo({})
            if (result && result.marketShiftInfoList) {
              result.marketShiftInfoList.forEach((item, index) => {
                return (item.color = this.classList[index].color)
              })
              this.marketShiftInfoList = result.marketShiftInfoList
              const tableData = {
                data: result.marketShiftInfoList || [],
                totals: Number(result.marketShiftInfoList.length) || 1000
              }
              resolve(tableData)
            }
          } catch (error) {
            reject(error)
            // this.$message({ type: 'error', message: error.msg || error, duration: 2000, showClose: true })
          }
        }
        newRequest()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  width: 100%;
  height: 50%;
  margin-bottom: 10px;
  display: flex;
  .left-top {
    padding: 10px;
    flex: 1;
    background: #fff;
    margin-right: 10px;
    .btn-box {
      margin-bottom: 10px;
      height: 30px;
      line-height: 30px;
      display: flex;
      position: relative;
      .records-box {
        border-radius: 3px;
        padding: 0 15px;
        background: #1684fc;
        color: #fff;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      .title {
        font-weight: 600;
      }
      div {
        margin-right: 15px;
      }
      .check-box {
        display: flex;
        cursor: pointer;
        span {
          display: inline-block;
          padding: 0px 15px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #1684fc;
          color: #1684fc;
        }
        .check-on {
          background: #1684fc;
          color: #fff;
        }
      }
    }

    .table-box {
      width: 100%;
      height: calc(100% - 40px);
      border: 1px solid #f2f2f2;
      .table-top {
        height: 80px;
        width: 100%;
        display: flex;
        background: #f5f7fa;
        .top-item {
          width: calc(100% / 10);
          height: 100%;
          text-align: center;
          border-right: 1px solid #f2f2f2;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .top-item:last-child {
          border-right: none;
        }
        .dateNow-box {
          background: #fff7e6;
        }
      }
      .table-content {
        height: 50px;
        width: 100%;
        display: flex;
        .content-item {
          width: calc(100% / 10);
          height: 100%;
          line-height: 50px;
          text-align: center;
          border-right: 1px solid #f2f2f2;
          border-bottom: 1px solid #f2f2f2;
          position: relative;
        }
        .content-item:last-child {
          border-right: none;
        }
        .checkIn-item {
          background: #ffeedd !important;
          border: 1px solid #ff8c19;
        }
      }
    }
  }
}

.right-top {
  width: 440px;
  height: 100%;
  margin-top: -20px;
  background: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .time-box {
    font-size: 22px;
  }
  .tips {
    font-size: 13px;
  }
  .flushed {
    cursor: pointer;
    padding: 3px 60px;
    border-radius: 5px;
    color: #fff;
    background: #1684fc;
  }
}
.bottom-box {
  flex: 1;
  display: flex;
  .left-bottom {
    flex: 1;
    background: #fff;
    margin-right: 10px;
    padding: 10px;
    .btn-box {
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      display: flex;
      .title {
        font-weight: 600;
      }
      div {
        margin-right: 15px;
      }
    }
  }
  .right-bottom {
    width: 440px;
    height: 100%;
    background: #fff;
    padding: 10px;
    .btn-box {
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      display: flex;
      .title {
        font-weight: 600;
      }
    }
  }
}
.application-box {
  border: 1px solid #dbdbdb;
  .top-box {
    width: 100%;
    height: 40px;
    margin-bottom: 0;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    .top-item {
      width: 50%;
      height: 100%;
      text-align: center;
      line-height: 40px;
      background: #e8e8e8;
    }
    .top-item:last-child {
      border-left: 1px solid #dbdbdb;
    }
  }
  .content-box {
    width: 100%;
    height: 40px;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    .content-item {
      width: 50%;
      height: 100%;
      text-align: center;
      line-height: 40px;
      background: #ffffff;
    }
    .content-item:last-child {
      border-left: 1px solid #dbdbdb;
    }
  }
}
.btn1 {
  border-radius: 3px;
  padding: 0 15px;
  background: #1684fc;
  color: #fff;
  cursor: pointer;
}
</style>
